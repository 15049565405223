export default {
    //feminino vgbl e pgbl
    "fator_feminino": [
        //vgbl
        [
            {
                idade: 0,
                fator: 35.509781462
            },
            {
                idade: 1,
                fator: 35.398423868
            },
            {
                idade: 2,
                fator: 35.277395703
            },
            {
                idade: 3,
                fator: 35.152009159
            },
            {
                idade: 4,
                fator: 35.022164154
            },
            {
                idade: 5,
                fator: 34.888300690
            },
            {
                idade: 6,
                fator: 34.750793322
            },
            {
                idade: 7,
                fator: 34.609760454
            },
            {
                idade: 8,
                fator: 34.465208959
            },
            {
                idade: 9,
                fator: 34.317109845
            },
            {
                idade: 10,
                fator: 34.165404850
            },
            {
                idade: 11,
                fator: 34.010033809
            },
            {
                idade: 12,
                fator: 33.850924269
            },
            {
                idade: 13,
                fator: 33.688011893
            },
            {
                idade: 14,
                fator: 33.521243856
            },
            {
                idade: 15,
                fator: 33.350612732
            },
            {
                idade: 16,
                fator: 33.176183605
            },
            {
                idade: 17,
                fator: 32.998221728
            },
            {
                idade: 18,
                fator: 32.816482482
            },
            {
                idade: 19,
                fator: 32.631102119
            },
            {
                idade: 20,
                fator: 32.442133542
            },
            {
                idade: 21,
                fator: 32.249313101
            },
            {
                idade: 22,
                fator: 32.052301276
            },
            {
                idade: 23,
                fator: 31.850980710
            },
            {
                idade: 24,
                fator: 31.645034478
            },
            {
                idade: 25,
                fator: 31.433885385
            },
            {
                idade: 26,
                fator: 31.217430334
            },
            {
                idade: 27,
                fator: 30.995537780
            },
            {
                idade: 28,
                fator: 30.768291997
            },
            {
                idade: 29,
                fator: 30.535811722
            },
            {
                idade: 30,
                fator: 30.298036807
            },
            {
                idade: 31,
                fator: 30.054676922
            },
            {
                idade: 32,
                fator: 29.805434600
            },
            {
                idade: 33,
                fator: 29.550200985
            },
            {
                idade: 34,
                fator: 29.288925777
            },
            {
                idade: 35,
                fator: 29.021820487
            },
            {
                idade: 36,
                fator: 28.749229165
            },
            {
                idade: 37,
                fator: 28.470984676
            },
            {
                idade: 38,
                fator: 28.186633254
            },
            {
                idade: 39,
                fator: 27.895703117
            },
            {
                idade: 40,
                fator: 27.597871061
            },
            {
                idade: 41,
                fator: 27.292971216
            },
            {
                idade: 42,
                fator: 26.981049870
            },
            {
                idade: 43,
                fator: 26.662597640
            },
            {
                idade: 44,
                fator: 26.337756297
            },
            {
                idade: 45,
                fator: 26.006402441
            },
            {
                idade: 46,
                fator: 25.668347209
            },
            {
                idade: 47,
                fator: 25.323591155
            },
            {
                idade: 48,
                fator: 24.972423172
            },
            {
                idade: 49,
                fator: 24.614632819
            },
            {
                idade: 50,
                fator: 24.250586773
            },
            {
                idade: 51,
                fator: 23.879844535
            },
            {
                idade: 52,
                fator: 23.502126039
            },
            {
                idade: 53,
                fator: 23.116798350
            },
            {
                idade: 54,
                fator: 22.724210246
            },
            {
                idade: 55,
                fator: 22.324866097
            },
            {
                idade: 56,
                fator: 21.918950518
            },
            {
                idade: 57,
                fator: 21.506834546
            },
            {
                idade: 58,
                fator: 21.088506841
            },
            {
                idade: 59,
                fator: 20.663644532
            },
            {
                idade: 60,
                fator: 20.231791520
            },
            {
                idade: 61,
                fator: 19.793251506
            },
            {
                idade: 62,
                fator: 19.348142247
            },
            {
                idade: 63,
                fator: 18.896458573
            },
            {
                idade: 64,
                fator: 18.438834257
            },
            {
                idade: 65,
                fator: 17.975628049
            },
            {
                idade: 66,
                fator: 17.508043429
            },
            {
                idade: 67,
                fator: 17.035997578
            },
            {
                idade: 68,
                fator: 16.559768660
            },
            {
                idade: 69,
                fator: 16.080422773
            },
            {
                idade: 70,
                fator: 15.598830342
            },
            {
                idade: 71,
                fator: 15.115694341
            },
            {
                idade: 72,
                fator: 14.629982153
            },
            {
                idade: 73,
                fator: 14.142094438
            },
            {
                idade: 74,
                fator: 13.651309339
            },
            {
                idade: 75,
                fator: 13.156472662
            },
            {
                idade: 76,
                fator: 12.656656935
            },
            {
                idade: 77,
                fator: 12.153434191
            },
            {
                idade: 78,
                fator: 11.648715752
            },
            {
                idade: 79,
                fator: 11.146000327
            },
            {
                idade: 80,
                fator: 10.648868019
            },
            {
                idade: 81,
                fator: 10.161001663
            },
            {
                idade: 82,
                fator: 9.683080232
            },
            {
                idade: 83,
                fator: 9.214085338
            },
            {
                idade: 84,
                fator: 8.751654558
            },
            {
                idade: 85,
                fator: 8.296346022
            },
            {
                idade: 86,
                fator: 7.852303654
            },
            {
                idade: 87,
                fator: 7.427796051
            },
            {
                idade: 88,
                fator: 7.026178559
            },
            {
                idade: 89,
                fator: 6.644708081
            },
            {
                idade: 90,
                fator: 6.279139549
            },
            {
                idade: 91,
                fator: 5.927252691
            },
            {
                idade: 92,
                fator: 5.590267304
            },
            {
                idade: 93,
                fator: 5.266044021
            },
            {
                idade: 94,
                fator: 4.960040041
            },
            {
                idade: 95,
                fator: 4.672470787
            },
            {
                idade: 96,
                fator: 4.402821921
            },
            {
                idade: 97,
                fator: 4.144275593
            },
            {
                idade: 98,
                fator: 3.891624718
            },
            {
                idade: 99,
                fator: 3.644217242
            },
            {
                idade: 100,
                fator: 3.400995230
            },
            {
                idade: 101,
                fator: 3.144348745
            },
            {
                idade: 102,
                fator: 2.891976895
            },
            {
                idade: 103,
                fator: 2.652330108
            },
            {
                idade: 104,
                fator: 2.425252887
            },
            {
                idade: 105,
                fator: 2.210541713
            },
            {
                idade: 106,
                fator: 2.007947644
            },
            {
                idade: 107,
                fator: 1.817181090
            },
            {
                idade: 108,
                fator: 1.637915695
            },
            {
                idade: 109,
                fator: 1.469793017
            },
            {
                idade: 110,
                fator: 1.312426736
            },
            {
                idade: 111,
                fator: 1.165407963
            },
            {
                idade: 112,
                fator: 1.028309722
            },
            {
                idade: 113,
                fator: 0.900691665
            },
            {
                idade: 114,
                fator: 0.782106008
            },
            {
                idade: 115,
                fator: 0.672109782
            },
            {
                idade: 116,
                fator: 0.570327324
            },
            {
                idade: 117,
                fator: 0.477261232
            },
            {
                idade: 118,
                fator: 0.458333330
            },
            {
                idade: 119,
                fator: 0.458333330
            }
        ],
        //pgbl
        [
            {
                idade: 0,
                fator: 31.004208569
            },
            {
                idade: 1,
                fator: 30.931405268
            },
            {
                idade: 2,
                fator: 30.850390994
            },
            {
                idade: 3,
                fator: 30.765781257
            },
            {
                idade: 4,
                fator: 30.677474129
            },
            {
                idade: 5,
                fator: 30.585841297
            },
            {
                idade: 6,
                fator: 30.491199560
            },
            {
                idade: 7,
                fator: 30.393642600
            },
            {
                idade: 8,
                fator: 30.293166532
            },
            {
                idade: 9,
                fator: 30.189735806
            },
            {
                idade: 10,
                fator: 30.083288753
            },
            {
                idade: 11,
                fator: 29.973761537
            },
            {
                idade: 12,
                fator: 29.861078951
            },
            {
                idade: 13,
                fator: 29.745172366
            },
            {
                idade: 14,
                fator: 29.625982792
            },
            {
                idade: 15,
                fator: 29.503491002
            },
            {
                idade: 16,
                fator: 29.377741930
            },
            {
                idade: 17,
                fator: 29.248958645
            },
            {
                idade: 18,
                fator: 29.116912649
            },
            {
                idade: 19,
                fator: 28.981712622
            },
            {
                idade: 20,
                fator: 28.843393861
            },
            {
                idade: 21,
                fator: 28.701709669
            },
            {
                idade: 22,
                fator: 28.556343658
            },
            {
                idade: 23,
                fator: 28.407175422
            },
            {
                idade: 24,
                fator: 28.253905014
            },
            {
                idade: 25,
                fator: 28.095996902
            },
            {
                idade: 26,
                fator: 27.933336152
            },
            {
                idade: 27,
                fator: 27.765781243
            },
            {
                idade: 28,
                fator: 27.593383298
            },
            {
                idade: 29,
                fator: 27.416224759
            },
            {
                idade: 30,
                fator: 27.234227540
            },
            {
                idade: 31,
                fator: 27.047105259
            },
            {
                idade: 32,
                fator: 26.854562568
            },
            {
                idade: 33,
                fator: 26.656471960
            },
            {
                idade: 34,
                fator: 26.452757683
            },
            {
                idade: 35,
                fator: 26.243580400
            },
            {
                idade: 36,
                fator: 26.029222252
            },
            {
                idade: 37,
                fator: 25.809503424
            },
            {
                idade: 38,
                fator: 25.583981786
            },
            {
                idade: 39,
                fator: 25.352194835
            },
            {
                idade: 40,
                fator: 25.113811326
            },
            {
                idade: 41,
                fator: 24.868640537
            },
            {
                idade: 42,
                fator: 24.616683785
            },
            {
                idade: 43,
                fator: 24.358348880
            },
            {
                idade: 44,
                fator: 24.093727350
            },
            {
                idade: 45,
                fator: 23.822668031
            },
            {
                idade: 46,
                fator: 23.544958091
            },
            {
                idade: 47,
                fator: 23.260556923
            },
            {
                idade: 48,
                fator: 22.969689087
            },
            {
                idade: 49,
                fator: 22.672120249
            },
            {
                idade: 50,
                fator: 22.368147082
            },
            {
                idade: 51,
                fator: 22.057321887
            },
            {
                idade: 52,
                fator: 21.739341381
            },
            {
                idade: 53,
                fator: 21.413571834
            },
            {
                idade: 54,
                fator: 21.080285788
            },
            {
                idade: 55,
                fator: 20.739902870
            },
            {
                idade: 56,
                fator: 20.392548364
            },
            {
                idade: 57,
                fator: 20.038522540
            },
            {
                idade: 58,
                fator: 19.677770854
            },
            {
                idade: 59,
                fator: 19.309946438
            },
            {
                idade: 60,
                fator: 18.934574059
            },
            {
                idade: 61,
                fator: 18.551887193
            },
            {
                idade: 62,
                fator: 18.161946391
            },
            {
                idade: 63,
                fator: 17.764696736
            },
            {
                idade: 64,
                fator: 17.360685147
            },
            {
                idade: 65,
                fator: 16.950203632
            },
            {
                idade: 66,
                fator: 16.534345836
            },
            {
                idade: 67,
                fator: 16.112996756
            },
            {
                idade: 68,
                fator: 15.686382577
            },
            {
                idade: 69,
                fator: 15.255480160
            },
            {
                idade: 70,
                fator: 14.821089098
            },
            {
                idade: 71,
                fator: 14.383856681
            },
            {
                idade: 72,
                fator: 13.942779099
            },
            {
                idade: 73,
                fator: 13.498213100
            },
            {
                idade: 74,
                fator: 13.049443455
            },
            {
                idade: 75,
                fator: 12.595332982
            },
            {
                idade: 76,
                fator: 12.134950740
            },
            {
                idade: 77,
                fator: 11.669760259
            },
            {
                idade: 78,
                fator: 11.201564452
            },
            {
                idade: 79,
                fator: 10.733713117
            },
            {
                idade: 80,
                fator: 10.269664386
            },
            {
                idade: 81,
                fator: 9.813006243
            },
            {
                idade: 82,
                fator: 9.364447904
            },
            {
                idade: 83,
                fator: 8.923056802
            },
            {
                idade: 84,
                fator: 8.486584460
            },
            {
                idade: 85,
                fator: 8.055600872
            },
            {
                idade: 86,
                fator: 7.634170755
            },
            {
                idade: 87,
                fator: 7.230413833
            },
            {
                idade: 88,
                fator: 6.847720356
            },
            {
                idade: 89,
                fator: 6.483546480
            },
            {
                idade: 90,
                fator: 6.133855786
            },
            {
                idade: 91,
                fator: 5.796560513
            },
            {
                idade: 92,
                fator: 5.472927957
            },
            {
                idade: 93,
                fator: 5.160935881
            },
            {
                idade: 94,
                fator: 4.866010335
            },
            {
                idade: 95,
                fator: 4.588463681
            },
            {
                idade: 96,
                fator: 4.327894208
            },
            {
                idade: 97,
                fator: 4.077687614
            },
            {
                idade: 98,
                fator: 3.832770719
            },
            {
                idade: 99,
                fator: 3.592531109
            },
            {
                idade: 100,
                fator: 3.355952003
            },
            {
                idade: 101,
                fator: 3.105632365
            },
            {
                idade: 102,
                fator: 2.858992213
            },
            {
                idade: 103,
                fator: 2.624409187
            },
            {
                idade: 104,
                fator: 2.401784306
            },
            {
                idade: 105,
                fator: 2.190968566
            },
            {
                idade: 106,
                fator: 1.991765240
            },
            {
                idade: 107,
                fator: 1.803934388
            },
            {
                idade: 108,
                fator: 1.627196539
            },
            {
                idade: 109,
                fator: 1.461237221
            },
            {
                idade: 110,
                fator: 1.305711073
            },
            {
                idade: 111,
                fator: 1.160247098
            },
            {
                idade: 112,
                fator: 1.024453150
            },
            {
                idade: 113,
                fator: 0.897920675
            },
            {
                idade: 114,
                fator: 0.780230702
            },
            {
                idade: 115,
                fator: 0.670966100
            },
            {
                idade: 116,
                fator: 0.569773614
            },
            {
                idade: 117,
                fator: 0.477169349
            },
            {
                idade: 118,
                fator: 0.458333330
            },
            {
                idade: 119,
                fator: 0.458333330
            },
        ]

    ],
    //masculino vgbl e pgbl
    "fator_masculino": [
        //vgbl
        [
            {
                idade: 0,
                fator: 34.743936327
            },
            {
                idade: 1,
                fator: 34.612930533
            },
            {
                idade: 2,
                fator: 34.472285650
            },
            {
                idade: 3,
                fator: 34.325915498
            },
            {
                idade: 4,
                fator: 34.174993558
            },
            {
                idade: 5,
                fator: 34.019921548
            },
            {
                idade: 6,
                fator: 33.860829309
            },
            {
                idade: 7,
                fator: 33.697737213
            },
            {
                idade: 8,
                fator: 33.530613321
            },
            {
                idade: 9,
                fator: 33.359400143
            },
            {
                idade: 10,
                fator: 33.184031297
            },
            {
                idade: 11,
                fator: 33.004431399
            },
            {
                idade: 12,
                fator: 32.820545982
            },
            {
                idade: 13,
                fator: 32.632358060
            },
            {
                idade: 14,
                fator: 32.439911189
            },
            {
                idade: 15,
                fator: 32.243325822
            },
            {
                idade: 16,
                fator: 32.042795944
            },
            {
                idade: 17,
                fator: 31.838588900
            },
            {
                idade: 18,
                fator: 31.632091072
            },
            {
                idade: 19,
                fator: 31.423389946
            },
            {
                idade: 20,
                fator: 31.213104779
            },
            {
                idade: 21,
                fator: 31.001041632
            },
            {
                idade: 22,
                fator: 30.786755402
            },
            {
                idade: 23,
                fator: 30.568681372
            },
            {
                idade: 24,
                fator: 30.345584637
            },
            {
                idade: 25,
                fator: 30.116467723
            },
            {
                idade: 26,
                fator: 29.880871846
            },
            {
                idade: 27,
                fator: 29.638687284
            },
            {
                idade: 28,
                fator: 29.390040293
            },
            {
                idade: 29,
                fator: 29.134872199
            },
            {
                idade: 30,
                fator: 28.872896502
            },
            {
                idade: 31,
                fator: 28.604277722
            },
            {
                idade: 32,
                fator: 28.329123266
            },
            {
                idade: 33,
                fator: 28.047560790
            },
            {
                idade: 34,
                fator: 27.759765644
            },
            {
                idade: 35,
                fator: 27.465813731
            },
            {
                idade: 36,
                fator: 27.165388491
            },
            {
                idade: 37,
                fator: 26.858278300
            },
            {
                idade: 38,
                fator: 26.544040849
            },
            {
                idade: 39,
                fator: 26.222615943
            },
            {
                idade: 40,
                fator: 25.893927878
            },
            {
                idade: 41,
                fator: 25.558222251
            },
            {
                idade: 42,
                fator: 25.215502489
            },
            {
                idade: 43,
                fator: 24.866046535
            },
            {
                idade: 44,
                fator: 24.510071738
            },
            {
                idade: 45,
                fator: 24.147900820
            },
            {
                idade: 46,
                fator: 23.779135983
            },
            {
                idade: 47,
                fator: 23.403451800
            },
            {
                idade: 48,
                fator: 23.020582743
            },
            {
                idade: 49,
                fator: 22.631005177
            },
            {
                idade: 50,
                fator: 22.234949944
            },
            {
                idade: 51,
                fator: 21.832779875
            },
            {
                idade: 52,
                fator: 21.424756771
            },
            {
                idade: 53,
                fator: 21.011494806
            },
            {
                idade: 54,
                fator: 20.593319320
            },
            {
                idade: 55,
                fator: 20.170296000
            },
            {
                idade: 56,
                fator: 19.742344699
            },
            {
                idade: 57,
                fator: 19.309768437
            },
            {
                idade: 58,
                fator: 18.872617905
            },
            {
                idade: 59,
                fator: 18.430345075
            },
            {
                idade: 60,
                fator: 17.982467279
            },
            {
                idade: 61,
                fator: 17.529009139
            },
            {
                idade: 62,
                fator: 17.070320879
            },
            {
                idade: 63,
                fator: 16.607333884
            },
            {
                idade: 64,
                fator: 16.141220452
            },
            {
                idade: 65,
                fator: 15.673902696
            },
            {
                idade: 66,
                fator: 15.205198867
            },
            {
                idade: 67,
                fator: 14.734806624
            },
            {
                idade: 68,
                fator: 14.261307247
            },
            {
                idade: 69,
                fator: 13.785451952
            },
            {
                idade: 70,
                fator: 13.306934892
            },
            {
                idade: 71,
                fator: 12.829188765
            },
            {
                idade: 72,
                fator: 12.353501261
            },
            {
                idade: 73,
                fator: 11.883228484
            },
            {
                idade: 74,
                fator: 11.418522197
            },
            {
                idade: 75,
                fator: 10.960407936
            },
            {
                idade: 76,
                fator: 10.507865355
            },
            {
                idade: 77,
                fator: 10.063115179
            },
            {
                idade: 78,
                fator: 9.625757624
            },
            {
                idade: 79,
                fator: 9.194162900
            },
            {
                idade: 80,
                fator: 8.768528360
            },
            {
                idade: 81,
                fator: 8.347728386
            },
            {
                idade: 82,
                fator: 7.933098170
            },
            {
                idade: 83,
                fator: 7.522769762
            },
            {
                idade: 84,
                fator: 7.115969885
            },
            {
                idade: 85,
                fator: 6.715674811
            },
            {
                idade: 86,
                fator: 6.329074471
            },
            {
                idade: 87,
                fator: 5.959663542
            },
            {
                idade: 88,
                fator: 5.614028334
            },
            {
                idade: 89,
                fator: 5.287601718
            },
            {
                idade: 90,
                fator: 4.988874315
            },
            {
                idade: 91,
                fator: 4.701772866
            },
            {
                idade: 92,
                fator: 4.427681701
            },
            {
                idade: 93,
                fator: 4.148653459
            },
            {
                idade: 94,
                fator: 3.890789810
            },
            {
                idade: 95,
                fator: 3.639639267
            },
            {
                idade: 96,
                fator: 3.404870039
            },
            {
                idade: 97,
                fator: 3.184946068
            },
            {
                idade: 98,
                fator: 2.975418733
            },
            {
                idade: 99,
                fator: 2.774836398
            },
            {
                idade: 100,
                fator: 2.583071389
            },
            {
                idade: 101,
                fator: 2.399976246
            },
            {
                idade: 102,
                fator: 2.225384315
            },
            {
                idade: 103,
                fator: 2.059112374
            },
            {
                idade: 104,
                fator: 1.900961397
            },
            {
                idade: 105,
                fator: 1.750719387
            },
            {
                idade: 106,
                fator: 1.608161796
            },
            {
                idade: 107,
                fator: 1.473054447
            },
            {
                idade: 108,
                fator: 1.345154352
            },
            {
                idade: 109,
                fator: 1.224212398
            },
            {
                idade: 110,
                fator: 1.109974164
            },
            {
                idade: 111,
                fator: 1.002181840
            },
            {
                idade: 112,
                fator: 0.900575749
            },
            {
                idade: 113,
                fator: 0.804895913
            },
            {
                idade: 114,
                fator: 0.714884088
            },
            {
                idade: 115,
                fator: 0.630289785
            },
            {
                idade: 116,
                fator: 0.550914213
            },
            {
                idade: 117,
                fator: 0.477261232
            },
            {
                idade: 118,
                fator: 0.458333330
            },
            {
                idade: 119,
                fator: 0.458333330
            }

        ],
        //pgbl
        [
            {
                idade: 0,
                fator: 30.471747505
            }, {
                idade: 1,
                fator: 30.382577585
            }, {
                idade: 2,
                fator: 30.285138556
            }, {
                idade: 3,
                fator: 30.182833888
            }, {
                idade: 4,
                fator: 30.076675447
            }, {
                idade: 5,
                fator: 29.967001315
            }, {
                idade: 6,
                fator: 29.853911834
            }, {
                idade: 7,
                fator: 29.737411232
            }, {
                idade: 8,
                fator: 29.617457400
            }, {
                idade: 9,
                fator: 29.493985204
            }, {
                idade: 10,
                fator: 29.366921056
            }, {
                idade: 11,
                fator: 29.236182737
            }, {
                idade: 12,
                fator: 29.101705900
            }, {
                idade: 13,
                fator: 28.963458954
            }, {
                idade: 14,
                fator: 28.821463882
            }, {
                idade: 15,
                fator: 28.675811305
            }, {
                idade: 16,
                fator: 28.526658104
            }, {
                idade: 17,
                fator: 28.374227891
            }, {
                idade: 18,
                fator: 28.219746419
            }, {
                idade: 19,
                fator: 28.063287065
            }, {
                idade: 20,
                fator: 27.905400342
            }, {
                idade: 21,
                fator: 27.745912194
            }, {
                idade: 22,
                fator: 27.584421892
            }, {
                idade: 23,
                fator: 27.419519360
            }, {
                idade: 24,
                fator: 27.250079750
            }, {
                idade: 25,
                fator: 27.075182728
            }, {
                idade: 26,
                fator: 26.894385968
            }, {
                idade: 27,
                fator: 26.707558401
            }, {
                idade: 28,
                fator: 26.514780949
            }, {
                idade: 29,
                fator: 26.315967648
            }, {
                idade: 30,
                fator: 26.110825171
            }, {
                idade: 31,
                fator: 25.899466628
            }, {
                idade: 32,
                fator: 25.681954009
            }, {
                idade: 33,
                fator: 25.458368056
            }, {
                idade: 34,
                fator: 25.228833452
            }, {
                idade: 35,
                fator: 24.993385131
            }, {
                idade: 36,
                fator: 24.751699907
            }, {
                idade: 37,
                fator: 24.503547510
            }, {
                idade: 38,
                fator: 24.248484327
            }, {
                idade: 39,
                fator: 23.986412788
            }, {
                idade: 40,
                fator: 23.717219992
            }, {
                idade: 41,
                fator: 23.441087023
            }, {
                idade: 42,
                fator: 23.157973528
            }, {
                idade: 43,
                fator: 22.868091496
            }, {
                idade: 44,
                fator: 22.571598706
            }, {
                idade: 45,
                fator: 22.268751524
            }, {
                idade: 46,
                fator: 21.959141866
            }, {
                idade: 47,
                fator: 21.642424806
            }, {
                idade: 48,
                fator: 21.318307621
            }, {
                idade: 49,
                fator: 20.987183927
            }, {
                idade: 50,
                fator: 20.649222273
            }, {
                idade: 51,
                fator: 20.304715509
            }, {
                idade: 52,
                fator: 19.953864704
            }, {
                idade: 53,
                fator: 19.597201836
            }, {
                idade: 54,
                fator: 19.234993362
            }, {
                idade: 55,
                fator: 18.867264741
            }, {
                idade: 56,
                fator: 18.493904661
            }, {
                idade: 57,
                fator: 18.115160710
            }, {
                idade: 58,
                fator: 17.731045123
            }, {
                idade: 59,
                fator: 17.341005716
            }, {
                idade: 60,
                fator: 16.944546611
            }, {
                idade: 61,
                fator: 16.541646556
            }, {
                idade: 62,
                fator: 16.132591996
            }, {
                idade: 63,
                fator: 15.718222695
            }, {
                idade: 64,
                fator: 15.299614637
            }, {
                idade: 65,
                fator: 14.878566544
            }, {
                idade: 66,
                fator: 14.454890043
            }, {
                idade: 67,
                fator: 14.028279672
            }, {
                idade: 68,
                fator: 13.597360412
            }, {
                idade: 69,
                fator: 13.162819485
            }, {
                idade: 70,
                fator: 12.724337231
            }, {
                idade: 71,
                fator: 12.285176634
            }, {
                idade: 72,
                fator: 11.846569396
            }, {
                idade: 73,
                fator: 11.411747718
            }, {
                idade: 74,
                fator: 10.980885736
            }, {
                idade: 75,
                fator: 10.555001490
            }, {
                idade: 76,
                fator: 10.133145153
            }, {
                idade: 77,
                fator: 9.717492939
            }, {
                idade: 78,
                fator: 9.307701506
            }, {
                idade: 79,
                fator: 8.902230597
            }, {
                idade: 80,
                fator: 8.501299917
            }, {
                idade: 81,
                fator: 8.103843276
            }, {
                idade: 82,
                fator: 7.711179868
            }, {
                idade: 83,
                fator: 7.321515487
            }, {
                idade: 84,
                fator: 6.934106466
            }, {
                idade: 85,
                fator: 6.551864160
            }, {
                idade: 86,
                fator: 6.181844060
            }, {
                idade: 87,
                fator: 5.827537259
            }, {
                idade: 88,
                fator: 5.495492671
            }, {
                idade: 89,
                fator: 5.181361479
            }, {
                idade: 90,
                fator: 4.893591358
            }, {
                idade: 91,
                fator: 4.616536581
            }, {
                idade: 92,
                fator: 4.351618183
            }, {
                idade: 93,
                fator: 4.081215335
            }, {
                idade: 94,
                fator: 3.831016257
            }, {
                idade: 95,
                fator: 3.586866508
            }, {
                idade: 96,
                fator: 3.358335380
            }, {
                idade: 97,
                fator: 3.143983171
            }, {
                idade: 98,
                fator: 2.939479043
            }, {
                idade: 99,
                fator: 2.743429454
            }, {
                idade: 100,
                fator: 2.555742433
            }, {
                idade: 101,
                fator: 2.376304949
            }, {
                idade: 102,
                fator: 2.204983412
            }, {
                idade: 103,
                fator: 2.041626195
            }, {
                idade: 104,
                fator: 1.886064346
            }, {
                idade: 105,
                fator: 1.738114354
            }, {
                idade: 106,
                fator: 1.597578553
            }, {
                idade: 107,
                fator: 1.464248015
            }, {
                idade: 108,
                fator: 1.337903377
            }, {
                idade: 109,
                fator: 1.218317533
            }, {
                idade: 110,
                fator: 1.105256485
            }, {
                idade: 111,
                fator: 0.998481301
            }, {
                idade: 112,
                fator: 0.897749674
            }, {
                idade: 113,
                fator: 0.802817557
            }, {
                idade: 114,
                fator: 0.713441234
            }, {
                idade: 115,
                fator: 0.629383355
            }, {
                idade: 116,
                fator: 0.550456483
            }, {
                idade: 117,
                fator: 0.477169349
            }, {
                idade: 118,
                fator: 0.458333330
            }, {
                idade: 119,
                fator: 0.458333330
            }
        ]
    ]
}