import React from "react"
import { Accordion, AccordionItem } from 'react-sanfona'

export default props => (
  <React.Fragment>
    <Accordion className="plans-aside-list d-print-none">
      {props.asideData.map((plan, index) => {
        return (
          <AccordionItem title={plan.title} className="plan-aside-item" expanded={plan.key === props.currentKey} key={index}>            
            {plan.topics.map((topic, i) => (
              <div key={i} className={"plan-aside-subitem" + (props.currentPlan.key === topic.key ? " active" : "")}  onClick={() => props.handleAsideClick(plan.banner, topic, plan.key)}>{topic.title}</div>
            ))}
          </AccordionItem>
        );
      })}
    </Accordion>
  </React.Fragment>
)