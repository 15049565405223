import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
// import Page from "react-page-loading"

//Package CSS & JS
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap/dist/js/bootstrap.min.js"
import "animate.css/animate.min.css"

//Template SCSS & CSS Style
import "./assets/css/style.scss"
import "./assets/css/responsive.scss"

//Component Import
import Aspecir from "./pages/Aspecir"
import SiteContact from './pages/SiteContact'
import SiteNews from './pages/SiteNews'
import SitePlans from './pages/SitePlans'
import SiteAporte from './pages/SiteAporte'
import SiteCertificate from './pages/SiteCertificate'

class App extends Component {
    render() {
        return (
            <div className="App">
                <Switch >
                    <Route
                        exact
                        path="/"
                        render={(props) => <Aspecir {...props} page="aspecir" title="Aspecir Previdência" />}
                    />
                    <Route
                        exact
                        path="/noticias"
                        render={(props) => <SiteNews {...props} page="aspecir" title="Aspecir Previdência" />}
                    />
                    <Route
                        exact
                        path="/contato"
                        render={(props) => <SiteContact {...props} page="aspecir" title="Aspecir Previdência" />}
                    />
                    <Route
                        exact
                        path="/planos"
                        render={(props) => <SitePlans {...props} page="aspecir" title="Aspecir Previdência" />}
                    />
                    <Route
                        exact
                        path="/aporte"
                        render={(props) => <SiteAporte {...props} page="aspecir" title="Aspecir Previdência" />}
                    />
                    <Route
                        exact
                        path="/certificado"
                        render={(props) => <SiteCertificate {...props} page="aspecir" title="Aspecir Previdência" />}
                    />
                </Switch>
            </div>
        );
    }
}
export default App;
