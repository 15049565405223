import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"

import ReactHtmlParser from 'react-html-parser'

export class Feadback extends Component {
    render() {
        const style = {
            singleFeedback: {
                cursor: 'pointer',
            },
        }
        //Start Feadback Loop
        const feadbackdata = this.props.feadbacksData.map((feadback, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12 feedback-container" key={index}>
                <Link to={{
                    pathname: this.props.newsPageUrl,
                    state: { id: feadback.id }
                }}>
                    <div className="single-feedback" style={style.singleFeedback}>
                        <div className="mb-3">
                            <div className="img mb-2" title={feadback.image_description} style={{ backgroundImage: feadback.image ? "url('" + feadback.image.url + "')" : "url('./avatar.png')" }}></div>
                            <h4 className="mb-0">
                                {feadback.title.substr(0, 45)}
                                {feadback.title.length > 45 ? '...' : ''}
                            </h4>
                            <small>{feadback.created_at}</small>
                        </div>
                        <p className="mb-3">
                            {ReactHtmlParser(feadback.preview.substr(0, 200))}...
                        </p>
                    </div>
                </Link>
            </div>
        ));
        //End Feadback Loop
        return (
            <React.Fragment>
                <section id="news" className="feedback-area ptb-50 bg-f9f9f9">
                    <div className="container">
                        <div className="section-title">
                            <h2>{this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b></h2>
                            <Link className="btn btn-primary animated fadeInDown slow opacityOne" to={this.props.newsPageUrl}>{this.props.buttonText}</Link>
                        </div>
                        <div className="row">
                            {feadbackdata}                            
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
Feadback.PropsTypes = {
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    feadbacksData: PropTypes.array,

}
Feadback.defaultProps = {
    sectionTitle: "",
    sectionTitleSpan: "",
    feadbacksData: [
        {
            title: "",
            created_at: "",
            preview: "",
            image_description: "",
            image: {
                url: ""
            }
        },
    ]

}
export default Feadback
