let siteId = 1;
const url = process.env.REACT_APP_API_URL;

export default {
  PAGE_ASPECIR: "aspecir",
  APP_NAME_ASPECIR: "Aspecir Previdência",
  LOGO_ASPECIR: require("../../src/assets/img/logo-aspecir.png"),
  LOGO_NEGATIVO_ASPECIR: require("../../src/assets/img/logo-aspecir-negativo.png"),
  PORTAL_CLIENTE_URL: "https://cliente.grupoaspecir.com.br",
  PAGE_UNIAO_SEGURADORA: "https://uniaoseguradora.com.br",
  SIMULADOR_TAXA1: 0.09,
  SIMULADOR_TAXA2: 0,
  REACT_APP_STORAGE_URL: "https://storage.googleapis.com/aspecirlv",

  getSiteUrl(page) {
    switch (page) {
      case this.PAGE_ASPECIR:
        return `${url}/sites/${siteId}`;
      default:
        return null;
    }
  },

  getSitePageUrl() {
    return `${url}/sites/${siteId}`;
  },

  getNewsUrl(page) {
    return `${url}/sites/${siteId}/noticias?page=${page || "1"}`;
  },

  getContact() {
    return url + "contato";
  },

  getRepresentative() {
    return url + "/representatives";
  },

  getApiChatBotUrl() {
    return url + "/chat_bot";
  },

  getProperties() {
    return url + "/propriedades";
  },

  getHolidaysUrl() {
    return `${url}/holidays_config?active_holiday=true`;
  },

  getContentUrl() {
    return `${url}/sites/${siteId}/conteudo`;
  },

  getAporte() {
    return url + "/aporte";
  },

  getSimulador() {
    return url + "/simulador/contato";
  },

  getInternalPageData(page) {
    let site, logo, logoNegativo;

    switch (page) {
      case this.PAGE_ASPECIR:
        site = this.APP_NAME_ASPECIR;
        logo = this.LOGO_ASPECIR;
        logoNegativo = this.LOGO_NEGATIVO_ASPECIR;
        break;
      case this.PAGE_GRUPO_ASPECIR:
        site = this.APP_NAME_GRUPO_ASPECIR;
        logo = this.LOGO_GRUPO_ASPECIR;
        logoNegativo = this.LOGO_NEGATIVO_GRUPO_ASPECIR;
        break;
      case this.PAGE_UNIAO_SEGURADORA:
        site = this.APP_NAME_UNIAO_SEGURADORA;
        logo = this.LOGO_UNIAO_SEGURADORA;
        logoNegativo = this.LOGO_NEGATIVO_UNIAO_SEGURADORA;
        break;
      default:
        return;
    }

    return [site, logo, logoNegativo];
  },

  getNewUrl() {
    return `${url}/noticias`;
  },
};
