import React, { Component } from "react";

import consts from "../utils/constants";
import texts from "../utils/texts";
import global from "../utils/global";
import axios from "axios";

import NavBar from "../components/NavBarInterna";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import AporteTable from "../components/aporte/Table";

import { Col, Form, Button, Alert } from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";

class SitePlansProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      annualGrossIncome: 0,
      annualGrossIncomeFormated: "",
      dependentsNumber: 0,
      inssContributions: 0,
      inssContributionsFormated: "",
      educationExpenses: 0,
      educationExpensesFormated: "",
      medicalExpenses: 0,
      medicalExpensesFormated: "",
      supplementaryPensionPayment: 0,
      supplementaryPensionPaymentFormated: "",
      aporteTableData: [],
      name: "",
      email: "",
      phone: "",
      cpf: "",
      isLoading: false,
    };

    this.handleMoneyFields = this.handleMoneyFields.bind(this);
    this.handleFields = this.handleFields.bind(this);
    this.handleAnnualValuesSubmit = this.handleAnnualValuesSubmit.bind(this);
    this.handleContactSubmit = this.handleContactSubmit.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 200);
  }

  handleMoneyFields = (e) => {
    let n = Number(global.getOnlyNumbers(e.target.value));

    this.setState({
      [e.target.name]: n / 100,
      [e.target.name + "Formated"]: global.setMoneyWithDecimalMask(n),
    });
  };

  handleFields = (e, mask) => {
    if (mask === "phone")
      this.setState({ [e.target.name]: global.setPhoneMask(e.target.value) });
    else if (mask === "cpf")
      this.setState({ [e.target.name]: global.setCpfMask(e.target.value) });
    else this.setState({ [e.target.name]: e.target.value });

    // this.setState({ validationMessage: "" })
  };

  handleAnnualValuesSubmit = () => {
    this.setState({
      aporteTableData: global.getAporteTableValues(
        this.state.annualGrossIncome,
        Number(this.state.dependentsNumber),
        this.state.inssContributions,
        this.state.educationExpenses,
        this.state.medicalExpenses,
        this.state.supplementaryPensionPayment
      ),
    });
  };

  handleContactSubmit = () => {
    let that = this;
    that.setState({ isLoading: true });

    axios
      .post(consts.getAporte(), this.state, {
        headers: { Accept: "application/json" },
      })
      .then(function() {
        that.setState({
          successMsg: "Mensagem enviada!",
          isLoading: false,
          phone: "",
          cpf: "",
        });
        document.getElementById("aporteContactForm").reset();
      })
      .catch(function(error) {});
  };

  render() {
    const page = this.props.page;
    const [site, logo, logoNegativo] = consts.getInternalPageData(page);

    return (
      <div className={`internal-page aporte-page ${page}-page`}>
        <div className="main-container">
          <NavBar
            site={site}
            logo={logo}
            logoNegativo={logoNegativo}
            page={page}
          />
          <section>
            <div className="container">
              <div className="row mb-5">
                <div className="col-lg-4 mb-3">
                  <h4>Valores Anuais</h4>
                  <ValidationForm
                    id="annualValuesForm"
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.handleAnnualValuesSubmit();
                    }}
                  >
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Sua renda bruta Anual</Form.Label>
                        <TextInput
                          name="annualGrossIncome"
                          type="text"
                          required
                          errorMessage={{
                            required: "Por favor, informe o valor.",
                          }}
                          className="form-control"
                          autoComplete="off"
                          placeholder="R$"
                          onChange={this.handleMoneyFields}
                          value={this.state.annualGrossIncomeFormated}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Nº de Dependentes</Form.Label>
                        <Form.Control
                          min={0}
                          type="number"
                          name="dependentsNumber"
                          value={this.state.dependentsNumber}
                          onChange={this.handleFields}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Suas contribuições ao INSS</Form.Label>
                        <Form.Control
                          placeholder="R$"
                          name="inssContributions"
                          value={this.state.inssContributionsFormated}
                          onChange={this.handleMoneyFields}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Despesas com educação</Form.Label>
                        <Form.Control
                          placeholder="R$"
                          name="educationExpenses"
                          value={this.state.educationExpensesFormated}
                          onChange={this.handleMoneyFields}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Despesas médicas</Form.Label>
                        <Form.Control
                          placeholder="R$"
                          name="medicalExpenses"
                          value={this.state.medicalExpensesFormated}
                          onChange={this.handleMoneyFields}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Pagto Prev Complementar</Form.Label>
                        <Form.Control
                          placeholder="R$"
                          name="supplementaryPensionPayment"
                          value={this.state.supplementaryPensionPaymentFormated}
                          onChange={this.handleMoneyFields}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} className="text-center">
                        <Button variant="primary" type="submit">
                          Simular
                        </Button>
                      </Form.Group>
                    </Form.Row>
                  </ValidationForm>
                </div>
                <div className="col-lg-5 mb-3">
                  <h4>Cálculo do Benefício Fiscal IRPF 2020</h4>
                  <AporteTable data={this.state.aporteTableData} />
                  <small>
                    Os valores apresentados refletem simulação, podendo sofrer
                    variações conforme o caso e legislação vigente.
                  </small>
                </div>
                <div className="col-lg-3 mb-3">
                  <h4>Solicite mais informações</h4>
                  <small className="mb-3 d-block">
                    Entraremos em contato para esclarecer dúvidas e ajudar em
                    seu aporte. Basta enviar seus dados.
                  </small>
                  {this.state.isLoading ? (
                    <div className="loader"></div>
                  ) : (
                    <ValidationForm
                      id="aporteContactForm"
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.handleContactSubmit();
                      }}
                    >
                      {this.state.successMsg && (
                        <Alert variant="info">{this.state.successMsg}</Alert>
                      )}
                      <Form.Row>
                        <Form.Group as={Col}>
                          <TextInput
                            name="name"
                            required
                            errorMessage={{
                              required: "Por favor, informe seu nome.",
                            }}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Nome"
                            onChange={this.handleFields}
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <TextInput
                            name="email"
                            type="email"
                            required
                            errorMessage={{
                              required: "Por favor, informe seu e-mail.",
                              type: "Este não é um e-mail válido!",
                            }}
                            className="form-control"
                            autoComplete="off"
                            placeholder="E-mail"
                            onChange={this.handleFields}
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <TextInput
                            name="phone"
                            type="text"
                            errorMessage={{
                              type: "Este não é um telefone válido!",
                              minLength:
                                "Necessário informar 10 caracteres, no mínimo",
                            }}
                            className="form-control"
                            minLength={14}
                            maxLength={15}
                            autoComplete="off"
                            placeholder="Telefone"
                            onChange={(e) => this.handleFields(e, "phone")}
                            value={this.state.phone}
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <TextInput
                            name="cpf"
                            type="text"
                            errorMessage={{
                              type: "Este não é um CPF válido!",
                              minLength: "Necessário informar 11 caracteres",
                            }}
                            className="form-control"
                            minLength={14}
                            maxLength={14}
                            autoComplete="off"
                            placeholder="CPF"
                            onChange={(e) => this.handleFields(e, "cpf")}
                            value={this.state.cpf}
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} className="text-center">
                          <Button variant="primary" type="submit">
                            Enviar
                          </Button>
                        </Form.Group>
                      </Form.Row>
                    </ValidationForm>
                  )}
                </div>
              </div>
            </div>
            <Faq topics={texts.aporte} />
          </section>
          <Footer companyName={site} />
        </div>
      </div>
    );
  }
}
export default SitePlansProducts;
