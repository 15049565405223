import React from "react"

export default props => {
    let [
        receitaSemAporte,
        receitaComAporte,
        inssSemAporte,
        inssComAporte,
        dependentesSemAporte,
        dependentesComAporte,
        educacaoSemAporte,
        educacaoComAporte,
        medicoSemAporte,
        medicoComAporte,
        aspecirLuterprevSemAporte,
        aspecirLuterprevComAporte,
        baseCalculoAnoSemAporte,
        baseCalculoAnoComAporte,
        aliquotaSemAporte,
        aliquotaComAporte,
        impostoDevidoSemAporte,
        impostoDevidoComAporte,
        aporteFinal,
        beneficioComAporteFinal,
    ] = props.data

    return (
        < React.Fragment >
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th>Sem Aporte</th>
                        <th>Com Aporte</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Renda Bruta</td>
                        <td>R$ {receitaSemAporte}</td>
                        <td>R$ {receitaComAporte}</td>
                    </tr>
                    <tr>
                        <td>INSS</td>
                        <td>- R$ {inssSemAporte}</td>
                        <td>- R$ {inssComAporte}</td>
                    </tr>
                    <tr>
                        <td>Dependentes</td>
                        <td>- R$ {dependentesSemAporte}</td>
                        <td>- R$ {dependentesComAporte}</td>
                    </tr>
                    <tr>
                        <td>Educação</td>
                        <td>- R$ {educacaoSemAporte}</td>
                        <td>- R$ {educacaoComAporte}</td>
                    </tr>
                    <tr>
                        <td>Médico</td>
                        <td>- R$ {medicoSemAporte}</td>
                        <td>- R$ {medicoComAporte}</td>
                    </tr>
                    <tr>
                        <td>Aspecir/Luterprev</td>
                        <td>- R$ {aspecirLuterprevSemAporte}</td>
                        <td>- R$ {aspecirLuterprevComAporte}</td>
                    </tr>
                    <tr>
                        <td>Base cálculo</td>
                        <td className="bg-ffd800">R$ {baseCalculoAnoSemAporte}</td>
                        <td className="bg-ffd800">R$ {baseCalculoAnoComAporte}</td>
                    </tr>
                    <tr>
                        <td>Alíquota</td>
                        <td>R$ {aliquotaSemAporte}</td>
                        <td>R$ {aliquotaComAporte}</td>
                    </tr>
                    <tr>
                        <td>Imposto devido</td>
                        <td>R$ {impostoDevidoSemAporte}</td>
                        <td>R$ {impostoDevidoComAporte}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td className="text-right" colSpan="2">Aporte</td>
                        <td className="text-right">{aporteFinal}</td>
                    </tr>
                    <tr className="bg-ffd800">
                        <td className="text-right" colSpan="2">Benefício com Aporte</td>
                        <td className="text-right">{beneficioComAporteFinal}</td>
                    </tr>
                </tfoot>
            </table>
        </React.Fragment >
    )
}