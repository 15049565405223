import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Work extends Component {
  render() {
    return (
        <React.Fragment>
            <section className="work-with-us ptb-50">
                <div className="container">
                    <h3>{this.props.workTitle}</h3>
                    <span>{this.props.worksmallTitle}</span>                    
                </div>                
            </section>
        </React.Fragment>
    );
  }
}
Work.PropsTypes = {
    workTitle: PropTypes.string,
    worksmallTitle: PropTypes.string,    
}
Work.defaultProps = {
    workTitle: "Want to work with us?",
    worksmallTitle: "Lets talk about project",   
}
export default Work
