import React, { useEffect, useState } from "react";
import axios from "axios";
import consts from "../utils/constants";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

//Components List
import NavBar from "../components/NavBar";
import Banner from "../components/Banner";
import About from "../components/About";
import Services from "../components/Services";
import Feadback from "../components/Feadback";
import Work from "../components/Work";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";
import Section from "../components/Section";
import HolidayAlert from "../components/HolidayAlert/HolidayAlert";
import iconOffline from "../assets/img/icon_offline.png";

const Aspecir = () => {
  const [state, setState] = useState({
    address: "",
    complement: "",
    district: "",
    cep: "",
    city: "",
    state: "",
    email: "",
    phone: "",
    banners: [],
    news: [],
    isLoading: true,
  });
  const [disponibility, setDisponibility] = useState({
    status: "",
    text: "",
    script: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    axios.get(consts.getSitePageUrl()).then((resp) =>
      setState({
        ...state,
        address: resp.data.data.site.address,
        complement: resp.data.data.site.complement,
        district: resp.data.data.site.district,
        cep: resp.data.data.site.cep,
        city: resp.data.data.site.city,
        state: resp.data.data.site.state,
        email: resp.data.data.site.email,
        phone: resp.data.data.site.phone_number,
        banners: resp.data.data.site.banners,
        news: resp.data.data.site.posts,
        isLoading: false,
      })
    );
  }, []);

  useEffect(() => {
    if (disponibility.status === "online") {
      var scriptString = disponibility.script;
      const partes = scriptString.match(/"(.*?)"|'(.*?)'/g);

      if (partes) {
        const src = partes[0].replace(/"|'/g, "");
        const token = partes[1].replace(/"|'/g, "");
        const script = document.createElement("script");
        script.src = src;
        script.setAttribute("token", token);
        script.async = true;
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      }
    }
  }, [disponibility]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    axios
      .post(consts.getApiChatBotUrl(), { site: 1 })
      .then((resp) => {
        setDisponibility({
          status: "online",
          script: resp.data,
        });
      })
      .catch((error) => {
        setDisponibility({
          status: "offline",
          text: error.response.data.unavailability_message,
          script: "",
          image: error.response.data.image,
        });
      });
  }, []);

  return state.isLoading ? (
    <div className="loader"></div>
  ) : (
    <React.Fragment>
      <NavBar
        site={consts.APP_NAME_ASPECIR}
        logo={consts.LOGO_ASPECIR}
        logoNegativo={consts.LOGO_NEGATIVO_ASPECIR}
      />
      <Banner homeslidesData={state.banners} />

      <Section />

      <About
        item2topTitle="Sobre a"
        item2topTitlespan="Empresa"
        item2Title="Conheça um pouco sobre a"
        item2Titlespan="Aspecir"
        sectionImage={require("../assets/img/sobre-aspecir.jpg")}
        item2ContentTop="Pensando na segurança e no bem-estar das pessoas, um grupo de comerciantes e industriais criaram a ASPECIR Previdência - Associação de Pecúlios do Comércio e Indústria Riograndenses, em 1937. Desde então a ASPECIR é uma instituição de Previdência Complementar Aberta, estabelecida em valores éticos como transparência e responsabilidade, reconhecida por sua atuação no ramo previdenciário."
        item2ContentMiddle="Sempre inovando e desenvolvendo produtos que atendam às necessidades e proporcionem tranquilidade aos seus associados, a instituição é sólida e oferece segurança e a garantia de um futuro tranquilo."
        item2ContentBottom="A ASPECIR é a parceira ideal na operacionalização de convênios para consignação de pecúlios e empréstimos, com desconto em folha de pagamento, junto a organizações públicas e privadas."
      />

      <Services
        sectionTitle="Nossos"
        sectionTitleSpan="Planos"
        plansPageUrl="/planos"
        servicesData={[
          {
            icon: require("../assets/img/plan-assistenciafinanceira.png"),
            key: "assistencia-financeira#entenda-como-funciona",
            title: "Assistência Financeira",
            content:
              "Um auxílio para você organizar seu orçamento e realizar sonhos.",
          },
          {
            icon: require("../assets/img/plan-pgbl.png"),
            key: "previdencia#como-funciona",
            title: "Previdência Complementar",
            content:
              "Leia sobre os nossos planos (PGBL, PRGP e VGBL) e verifique qual melhor se encaixa ao seu perfil.",
          },
          {
            icon: require("../assets/img/plan-peculio.png"),
            key: "peculio#peculio",
            title: "Pecúlio",
            content:
              "Com a oportunidade de escolher entre pecúlio individual e pecúlio coletivo, oferece o plano ideal para você cuidar da sua família ou da sua equipe.",
          },
          {
            icon: require("../assets/img/plan-peculioseguro.png"),
            key: "peculio#peculio-com-seguro",
            title: "Pecúlio com Seguro",
            content:
              "Mais cobertura, mas facilidade de pagamento e mais tranquilidade para os beneficiados.",
          },
        ]}
      />

      <Feadback
        sectionTitleSpan="Notícias"
        buttonText="Mais Notícias"
        newsPageUrl="/noticias"
        feadbacksData={state.news}
      />

      <Work
        workTitle="Seja um corretor credenciado"
        worksmallTitle="Entre em contato conosco através do formulário abaixo"
      />
      <ContactUs page={"aspecir"} />
      {disponibility.status === "offline" && disponibility.text != null && (
        <div className="schedule_config">
          <Button onClick={handlePopoverOpen}>
            <img
              style={{ width: "50px", height: "50px" }}
              src={disponibility.image ? disponibility.image : iconOffline}
              alt=""
            />
          </Button>
          <Popover
            anchorEl={anchorEl}
            open={open}
            id={open ? "simple-popover" : undefined}
            style={{ width: "40%" }}
            onClose={handlePopoverClose}
            transformOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "center",
            }}
          >
            <div style={{ padding: "10px", whiteSpace: "pre-wrap" }}>{disponibility.text}</div>
          </Popover>
        </div>
      )}
      <Footer companyName={consts.APP_NAME_ASPECIR} />
      <HolidayAlert />
      <ScrollUpBtn />
    </React.Fragment>
  );
};

export default Aspecir;
