import React, { Component } from "react";
import { Link } from "react-scroll";
import { Navbar, Nav, Container } from "react-bootstrap";
import { CancelableEvents } from "browser-cancelable-events";
import consts from "../utils/constants";
import axios from "axios";

import Icofont from "react-icofont";
import SideNav from "../components/SideNav";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSticky: false,
      address: "",
      complement: "",
      district: "",
      cep: "",
      city: "",
      state: "",
      email: "",
      phone: "",
    };

    this.addSticky = this.addSticky.bind(this);
    this.cancelable = new CancelableEvents();
  }

  addSticky = (elem) => {
    if (window.scrollY > 170) {
      elem.classList.add("is-sticky");
      this.setState({ ...this.state, isSticky: true });
    } else {
      elem.classList.remove("is-sticky");
      this.setState({ ...this.state, isSticky: false });
    }
  };

  componentDidMount() {
    let elem = document.getElementById("navbar");
    this.cancelable.addDocumentEventListener("scroll", () =>
      this.addSticky(elem)
    );

    axios.get(consts.getSitePageUrl()).then((resp) =>
      this.setState({
        ...this.state,
        address: resp.data.data.site.address,
        complement: resp.data.data.site.complement,
        district: resp.data.data.site.district,
        cep: resp.data.data.site.cep,
        city: resp.data.data.site.city,
        state: resp.data.data.site.state,
        email: resp.data.data.site.email,
        phone: resp.data.data.site.phone_number,
      })
    );
  }

  componentWillUnmount() {
    this.cancelable.cancelAll();
  }

  closeNavbar() {
    if (window.matchMedia("screen and (max-width: 991px)").matches) {
      document.getElementById("collaspe-btn").click();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Navbar
          sticky="top"
          id="navbar"
          bg="light"
          expand="xl"
          className="navbar navbar-expand-xl navbar-light bg-light"
          collapseOnSelect={true}
        >
          <Container>
            <Navbar.Brand>
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={800}
              >
                {this.state.isSticky ? (
                  <img src={this.props.logo} alt={`Logo ${this.props.site}`} />
                ) : (
                  <img
                    src={this.props.logoNegativo}
                    alt={`Logo ${this.props.site}`}
                  />
                )}
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" id="collaspe-btn" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Item>
                  <Link
                    activeClass="active1"
                    to="home"
                    spy={true}
                    smooth={true}
                    duration={800}
                    className="nav-link"
                    onClick={this.closeNavbar}
                  >
                    Home
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    activeClass="active1"
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={800}
                    className="nav-link"
                    onClick={this.closeNavbar}
                  >
                    Planos
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    activeClass="active1"
                    to="news"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={800}
                    className="nav-link"
                    onClick={this.closeNavbar}
                  >
                    Notícias
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    activeClass="active1"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={800}
                    className="nav-link"
                    onClick={this.closeNavbar}
                  >
                    Contato
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <a href={consts.PORTAL_CLIENTE_URL} className="nav-link">
                    <Icofont icon="lock" />
                    Portal do Cliente
                  </a>
                </Nav.Item>
                <Nav.Item>
                  <SideNav
                    page={this.props.page}
                    logo={consts.LOGO_ASPECIR}
                    site={consts.APP_NAME_ASPECIR}
                    locationbolt={
                      this.state.address + " - " + this.state.complement
                    }
                    locationnormal={
                      this.state.district +
                      ", " +
                      this.state.cep +
                      ", " +
                      this.state.city +
                      "/" +
                      this.state.state
                    }
                    phonebolt={this.state.phone}
                    mailbolot={this.state.email}
                  />
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default NavBar;
