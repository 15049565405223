import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

export class Services extends Component {
    render() {
        //Start Services Loop
        const servicedata = this.props.servicesData.map((services, index) => (
            <div className="col-lg-3 col-md-6" key={index}>
                <div className="single-services">
                    <Link to={{
                        pathname: this.props.plansPageUrl,
                        state: { key: services.key }
                    }}>
                        <img src={services.icon} alt={"Ícone " + services.title} />
                        <h3>{services.title}</h3>
                        <p>{services.content}</p>

                        <div className="bg-number">{services.bgnumber}</div>
                    </Link>
                </div>
            </div>
        ));
        //End Services Loop
        return (
            <React.Fragment>
                <section id="services" className="services-area ptb-50">
                    <div className="container">
                        <div className="section-title">
                            <h2>{this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b></h2>
                        </div>
                        <div className="row">{servicedata}</div>
                    </div>
                    <div className="animation-box5">
                        <img src={this.props.animationImg} className="rotateme" alt="vector" />
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
Services.propsTypes = {
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    animationImg: PropTypes.string,
    productsPageUrl: PropTypes.string,
    servicesData: PropTypes.array
}
Services.defaultProps = {
    sectionTitle: "",
    sectionTitleSpan: "",
    animationImg: require("../../src/assets/img/vector-bg.png"),
    productsPageUrl: "",
    servicesData: []
}
export default Services

