import React, { Component } from "react";
import Propstypes from "prop-types";

import Aside from "./Aside";
import Main from "./Main";
import Simulator from "./Simulator";

export class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {
            banner: "",
            currentPlan: "",
            currentKey: "",
            isSimulator: false,
        }
    }

    componentDidMount() {
        if (this.props.planKey) {
            let keys = (this.props.planKey).split("#")
            this.props.asideData.forEach(element => {
                if (element.key === keys[0]) {
                    this.setState({
                        banner: element.banner,
                        currentKey: keys[0],
                    })

                    if (typeof keys[1] === 'undefined')
                        this.setState({
                            currentPlan: element.topics[0]
                        })
                    else {
                        element.topics.forEach(topic => {
                            if (topic.key === keys[1]) {
                                this.setState({
                                    currentPlan: topic
                                })
                                if (keys[1] === "simulador-de-previdencia")
                                    this.setState({ isSimulator: true })
                            }
                        })
                    }
                }
            });
        }
        else {
            this.setState({
                banner: this.props.asideData[0].banner,
                currentPlan: this.props.asideData[0].topics[0],
                currentKey: this.props.asideData[0].key
            })
        }
    }

    handleAsideClick = (banner, topic, planKey) => {
        this.setState({ banner: banner, currentPlan: topic, currentKey: planKey })

        if (topic.key === "simulador-de-previdencia")
            this.setState({ isSimulator: true })
        else
            this.setState({ isSimulator: false })
    }

    render() {
        return (
            <React.Fragment>
                <section className="plans-container">
                    <div className="container">
                        {this.state.banner &&
                            <img src={this.state.banner} className="plans-banner d-print-none" alt="" />}
                        <div className="row">
                            <div className="col-lg-4">
                                <Aside
                                    asideData={this.props.asideData}
                                    handleAsideClick={this.handleAsideClick}
                                    currentKey={this.state.currentKey}
                                    currentPlan={this.state.currentPlan}
                                />
                            </div>
                            <div className="col-lg-8">
                                {this.state.isSimulator ?
                                    <Simulator
                                        plan={this.state.currentPlan}
                                    />
                                    :
                                    <Main plan={this.state.currentPlan} />
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
Container.Propstypes = {
    asideData: Propstypes.array,
}
Container.defaultProps = {
    asideData: []
}
export default Container;
