import React, { Component } from "react";
import "../assets/css/style.scss";
import { Link } from "react-router-dom";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="mt-n3 align-items-center d-flex"
          style={{ height: "100px", backgroundColor: "#004c30"}}
        >
          <div
            className="flex-fill container d-flex align-items-center justify-content-center"
            style={{ height: "100%" }}
          >
            <h5 className="fw-bolder text-light text-center pointer">
              <i></i>
            </h5>
          </div>
          <Link
            to="/certificado"
            className="section-certificate-page h-100 flex-fill container d-flex align-items-center justify-content-center"
            style={{ cursor: "pointer" }}
          >
            <div>
              <h5 className="fw-bolder text-light text-center pointer">
                <i>CERTIFICADO</i>
              </h5>
            </div>
          </Link>
          <div
            className="flex-fill container d-flex align-items-center justify-content-center"
            style={{ height: "100%" }}
          >
            <h5 className="fw-bolder text-light text-center pointer">
              <i></i>
            </h5>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Section;
