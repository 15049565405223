import React, { Component } from "react";
import "../assets/css/style.scss";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Button from "react-bootstrap/Button";
import ModalCertificate from "./ModalCertificate";

class Certificate extends Component {
  constructor() {
    super();
    this.state = {
      sent: false,
      validated: false,
      touched: false,
      loading: false,
      showModal: false,
      inputValues: {
        name: "",
        birthdate: "",
        cpfcnpj: "",
        phone_number: "",
        email: "",
        lgpd_accepted: false,
        status: "novo",
      },
    };
  }

  baseUrl = "https://core2.grupoaspecir.com.br/api/v1/certificates";

  post = async (payload) => {
    this.setState({ loading: true });
    try {
      await axios.post(this.baseUrl, payload);
      this.setState({ loading: false, showModal: true, sent: true });
    } catch (error) {
      alert("Ocorreu um erro durante a postagem: " + error.message);
      // window.location.reload();
      this.setState({ loading: false });
    }
  };

  handleSubmit = (event) => {
    const form = event.currentTarget;
    const values = {
      ...this.state.inputValues,
      cpfcnpj: this.state.inputValues.cpfcnpj.replace(/\D/g, ""),
      birthdate: this.state.inputValues.birthdate + "T03:00",
    };
    if (
      form.checkValidity() === true &&
      this.isValidCPF(this.state.inputValues.cpfcnpj)
    ) {
      this.post(values);
      event.preventDefault();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ validated: true, touched: true });
  };

  handleCloseModal = () => {
    // window.location.reload();
    this.setState({ showModal: false });
  };

  handleInputChange = (inputName, e) => {
    const { inputValues } = this.state;
    const formElement = document.getElementById("FormId");

    if (inputName === "lgpd_accepted") {
      this.setState((prevState) => ({
        inputValues: {
          ...prevState.inputValues,
          [inputName]: !prevState.inputValues[inputName],
        },
      }));
    } else if (inputName === "cpfcnpj") {
      const rawValue = e.target.value;

      // Remove caracteres não numéricos e pega apenas os números
      const cleanedCPF = rawValue.replace(/\D/g, "");

      // Aplica a máscara para o CPF (xxx.xxx.xxx-xx)
      const formattedCPF = cleanedCPF.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );

      if (formElement) {
        formElement.classList.remove("was-validated");
      }

      this.setState({
        inputValues: {
          ...inputValues,
          [inputName]: formattedCPF,
        },
      });
    } else if (inputName === "phone_number") {
      const rawValue = e.target.value;

      // Remove caracteres não numéricos e pega apenas os números
      const cleanedNumber = rawValue.replace(/\D/g, "");

      // Aplica a máscara para o telefone
      const formattedNumber = cleanedNumber.replace(
        /(\d{2})(\d{5})(\d{4})/,
        "($1) $2-$3"
      );

      this.setState({
        inputValues: {
          ...inputValues,
          [inputName]: formattedNumber,
        },
      });

      if (formElement && this.state.touched) {
        formElement.classList.add("was-validated");
      }
    } else {
      this.setState({
        inputValues: {
          ...inputValues,
          [inputName]: e.target.value,
        },
      });

      if (formElement && this.state.touched) {
        formElement.classList.add("was-validated");
      }
    }
  };

  isValidCPF = (cpf) => {
    // Remove caracteres não numéricos
    const cleanedCPF = cpf.replace(/\D/g, "");

    // Verifica se o CPF tem 11 dígitos
    if (cleanedCPF.length !== 11) {
      return false;
    }

    // Verifica se todos os dígitos são iguais (caso comum de CPF inválido)
    const allDigitsEqual = /^(.)\1+$/.test(cleanedCPF);
    if (allDigitsEqual) {
      return false;
    }

    // Calcula e compara o primeiro dígito verificador
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cleanedCPF.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(cleanedCPF.charAt(9))) {
      return false;
    }

    // Calcula e compara o segundo dígito verificador
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cleanedCPF.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(cleanedCPF.charAt(10))) {
      return false && console.log(false);
    }
    return true;
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid-certificate-component">
          <div className="content-certificate-component mx-auto">
            <p className="title-certificate-component">Solicite seu</p>
            <p className="title-certificate-component">
              <strong>certificado</strong>
            </p>
          </div>
        </div>
        <div
          className="container"
          style={{ paddingBottom: "320px", paddingTop: "100px" }}
        >
          <div>
            <p className="text-dark h4 lead">
              <i>
                <strong>
                  Preencha os dados abaixo para a solicitação do seu
                  certificado:
                </strong>
              </i>
            </p>
          </div>
          <div className="mt-5">
            <p className="text-dark h5">
              <strong>Dados:</strong>
            </p>
            <Form
              noValidate
              validated={this.state.validated}
              onSubmit={this.handleSubmit}
              id="FormId"
              className="form_certificate_component"
            >
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Digite seu nome"
                    value={this.state.inputValues.name}
                    onChange={(e) => this.handleInputChange("name", e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor, digite seu nome
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Data de Nascimento</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    value={this.state.inputValues.birthdate}
                    onChange={(e) => this.handleInputChange("birthdate", e)}
                  />
                  <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Por favor, digite sua data de nascimento
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <Form.Label>CPF</Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Digite seu CPF"
                      required
                      className={
                        this.state.inputValues.cpfcnpj &&
                        this.state.touched &&
                        (this.isValidCPF(this.state.inputValues.cpfcnpj)
                          ? "is-valid"
                          : "is-invalid")
                      }
                      value={this.state.inputValues.cpfcnpj}
                      onChange={(e) => this.handleInputChange("cpfcnpj", e)}
                      pattern="\d{3}\.\d{3}\.\d{3}-\d{2}"
                    />
                    <Form.Control.Feedback type="valid">
                      {this.isValidCPF(this.state.inputValues.cpfcnpj) &&
                        "CPF válido!"}
                    </Form.Control.Feedback>

                    <Form.Control.Feedback type="invalid">
                      {!this.isValidCPF(this.state.inputValues.cpfcnpj) &&
                        this.state.touched &&
                        "CPF inválido."}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Digite seu email"
                    required
                    value={this.state.inputValues.email}
                    onChange={(e) => this.handleInputChange("email", e)}
                  />
                  <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Por favor, digite seu email.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom05">
                  <Form.Label>Número de Telefone</Form.Label>
                  <Form.Control
                    // type="number"
                    placeholder="Digite seu número de telefone"
                    className="form-control-no-spinner"
                    value={this.state.inputValues.phone_number}
                    onChange={(e) => this.handleInputChange("phone_number", e)}
                    pattern="\(\d{2}\) \d{5}-\d{4}$"
                    required
                  />
                  <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Por favor, digite seu número de telefone
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Form.Group className="mb-3 text-justify">
                <Form.Check
                  required
                  label="Autorizo, de acordo com a Lei no 13.709/18 - Lei Geral de
                  Proteção de Dados, a coleta, o tratamento, a inclusão e o
                  compartilhamento de minhas informações de adimplemento e
                  demais ocorrências relacionadas ao presente contrato com o
                  fim de formação/consulta de banco de dados visando subsidiar
                  a análise de riscos atuais e futuros e/ou subsidiar as
                  regulações de sinistros baseado no Art.7o, §5o da lei de
                  proteção de dados. Os dados pessoais coletados poderão ser
                  armazenados em servidores localizados no Brasil ou no
                  exterior e somente serão fornecidos a terceiros na forma da
                  Lei (Art. 33, IX LGPD) e/ou mediante ordem judicial. Os
                  dados pessoais serão tratados em conformidade com a Lei no
                  13.709/18, na forma especificada na política de
                  privacidade/termo de privacidade fornecido."
                  feedback="Você deve concordar antes de enviar"
                  // feedbackType="invalid"
                  onClick={() => this.handleInputChange("lgpd_accepted")}
                />
              </Form.Group>
              <div>
                <Button
                  type="submit"
                  className="mt-3"
                  style={{
                    cursor: this.state.loading
                      ? "wait"
                      : this.state.sent
                      ? "default"
                      : "pointer",
                    color: this.state.sent ? "#28a745" : "white",
                    backgroundColor: this.state.sent
                      ? "transparent"
                      : "#004c30",
                  }}
                  disabled={this.state.loading || this.state.sent}
                >
                  {this.state.loading
                    ? "Aguarde..."
                    : this.state.sent
                    ? "Solicitação Enviada!"
                    : "Solicitar"}
                </Button>
              </div>
            </Form>
          </div>
          <ModalCertificate
            show={this.state.showModal}
            handleClose={this.handleCloseModal}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default Certificate;
