import React, { Component } from "react";
import Propstypes from "prop-types";
import global from "../../utils/global";
import axios from "axios";

import {
  Col,
  Form,
  Button,
  InputGroup,
  Card,
  Badge,
  Alert,
} from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import constants from "../../utils/constants";

export class Simulator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validationMessage: "",
      showSimulatorResult: false,
      isFieldDisabled: false,
      currentAge: 0,
      retirementAge: 0,
      gender: "",
      incomeTax: "",
      inflation: 0,
      monthlyContribution: 0,
      formatedMonthlyContribution: "",
      maxVolumeMonthlyContribution: 100000,
      lifetimeMonthlyIncome: 0,
      lifetimeMonthlyIncomeDecimal: 0,
      formatedLifetimeMonthlyIncome: "",
      maxVolumeLifetimeMonthlyIncome: 100000,
      cumulativeTotal: 0,
      cumulativeTotalDecimal: 0,
      formatedCumulativeTotal: "",
      maxVolumeCumulativeTotal: 100000,
      fator: 0,
      meses: 0,
      showContactForm: false,
      name: "",
      email: "",
      phone: "",
      isLoading: false,
    };

    this.handleFields = this.handleFields.bind(this);

    this.handleSimulatorSubmit = this.handleSimulatorSubmit.bind(this);
    this.showContactForm = this.showContactForm.bind(this);
    this.handleRefreshSimulatorPage = this.handleRefreshSimulatorPage.bind(
      this
    );
    this.calculateMaxVolume = this.calculateMaxVolume.bind(this);

    this.handleMonthlyContributionField = this.handleMonthlyContributionField.bind(
      this
    );
    this.handleLifetimeMonthlyIncomeField = this.handleLifetimeMonthlyIncomeField.bind(
      this
    );
    this.handleCumulativeTotalField = this.handleCumulativeTotalField.bind(
      this
    );

    this.handleContactSubmit = this.handleContactSubmit.bind(this);
  }

  componentDidMount() {
    axios
      .get(constants.getProperties() + "?inflation=rel_percent")
      .then((resp) =>
        this.setState({
          ...this.state,
          inflation: parseFloat(resp.data.data.properties[0].value),
        })
      );
  }

  handleFields = (e, mask) => {
    if (mask === "phone")
      this.setState({ [e.target.name]: global.setPhoneMask(e.target.value) });
    else this.setState({ [e.target.name]: e.target.value });

    this.setState({ validationMessage: "" });
  };

  handleSimulatorSubmit = () => {
    if (this.state.currentAge <= 0) {
      this.setState({ validationMessage: "Informe a sua idade atual!" });
      return;
    } else if (this.state.retirementAge <= 0) {
      this.setState({
        validationMessage: "Informe a idade desejada para aposentadoria!",
      });
      return;
    } else if (this.state.retirementAge < this.state.currentAge) {
      this.setState({
        validationMessage:
          "Idade de aposentadoria deve ser maior que idade atual!",
      });
      return;
    } else if (this.state.retirementAge > 113) {
      this.setState({
        validationMessage: "Idade de aposentadoria não pode ser maior que 113!",
      });
      return;
    } else if (this.state.gender === "") {
      this.setState({ validationMessage: "Informe o sexo!" });
      return;
    } else if (this.state.incomeTax === "") {
      this.setState({ validationMessage: "Informe a declaração IRRF!" });
      return;
    } else {
      this.setState({
        validationMessage: "",
        showSimulatorResult: true,
        isFieldDisabled: true,
      });
    }

    this.setState({
      fator: global.getFator(
        this.state.gender,
        this.state.incomeTax,
        this.state.retirementAge
      ),
      meses: global.getMeses(this.state.retirementAge, this.state.currentAge),
    });
  };

  showContactForm = () => {
    this.setState({ showContactForm: true });
  };

  handleRefreshSimulatorPage = () => {
    document.getElementById("simulatorForm").reset();
    this.setState({
      isFieldDisabled: false,
      showSimulatorResult: false,
      monthlyContribution: 0,
      formatedMonthlyContribution: "",
      lifetimeMonthlyIncome: 0,
      lifetimeMonthlyIncomeDecimal: 0,
      formatedLifetimeMonthlyIncome: "",
      cumulativeTotal: 0,
      cumulativeTotalDecimal: 0,
      formatedCumulativeTotal: "",
      showContactForm: false,
      successMsg: "",
    });
  };

  calculateMaxVolume = () => {
    if (this.state.monthlyContribution >= 100000)
      this.setState({
        maxVolumeMonthlyContribution: this.state.monthlyContribution * 2,
      });
    else this.setState({ maxVolumeMonthlyContribution: 100000 });

    if (this.state.lifetimeMonthlyIncome >= 100000)
      this.setState({
        maxVolumeLifetimeMonthlyIncome: this.state.lifetimeMonthlyIncome * 2,
      });
    else this.setState({ maxVolumeLifetimeMonthlyIncome: 100000 });

    if (this.state.cumulativeTotal >= 100000)
      this.setState({
        maxVolumeCumulativeTotal: this.state.cumulativeTotal * 2,
      });
    else this.setState({ maxVolumeCumulativeTotal: 100000 });
  };

  handleMonthlyContributionField = (e, type) => {
    let mc;
    if (type === "slider") mc = e;
    else mc = Number(global.getOnlyNumbers(e.target.value));

    let [lmi, ct] = global.getValuesFromMonthlyContribution(
      mc,
      this.state.fator,
      this.state.incomeTax,
      this.state.retirementAge,
      this.state.currentAge,
      this.state.inflation
    );

    this.setState({
      monthlyContribution: mc,
      formatedMonthlyContribution: global.setMoneyMask(mc),
      lifetimeMonthlyIncome: lmi,
      // formatedLifetimeMonthlyIncome: global.setMoneyMask(lmi),
      lifetimeMonthlyIncomeDecimal: (lmi.toFixed(2) + "").split(".")[1],
      formatedLifetimeMonthlyIncome: global.setMoneyMask(
        (lmi + "").split(".")[0]
      ),
      cumulativeTotal: ct,
      cumulativeTotalDecimal: (ct.toFixed(2) + "").split(".")[1],
      // formatedCumulativeTotal: global.setMoneyMask(ct),
      formatedCumulativeTotal: global.setMoneyMask((ct + "").split(".")[0]),
    });
  };

  handleLifetimeMonthlyIncomeField = (e, type) => {
    let lmi;
    if (type === "slider") lmi = e;
    else lmi = Number(global.getOnlyNumbers(e.target.value));

    let [mc, ct] = global.getValuesFromLifetimeMonthlyIncome(
      lmi,
      this.state.meses,
      this.state.fator
    );

    this.setState({
      monthlyContribution: mc,
      formatedMonthlyContribution: global.setMoneyMask(mc),
      lifetimeMonthlyIncome: lmi,
      formatedLifetimeMonthlyIncome: global.setMoneyMask(lmi),
      cumulativeTotal: ct,
      formatedCumulativeTotal: global.setMoneyMask(ct),
    });
  };

  handleCumulativeTotalField = (e, type) => {
    let ct;
    if (type === "slider") ct = e;
    else ct = Number(global.getOnlyNumbers(e.target.value));

    let [mc, lmi] = global.getValuesFromCumulativeTotal(
      ct,
      this.state.meses,
      this.state.fator
    );

    this.setState({
      monthlyContribution: mc,
      formatedMonthlyContribution: global.setMoneyMask(mc),
      lifetimeMonthlyIncome: lmi,
      formatedLifetimeMonthlyIncome: global.setMoneyMask(lmi),
      cumulativeTotal: ct,
      formatedCumulativeTotal: global.setMoneyMask(ct),
    });
  };

  handleContactSubmit = () => {
    let that = this;
    that.setState({ isLoading: true });

    axios
      .post(constants.getSimulador(), this.state, {
        headers: { Accept: "application/json" },
      })
      .then(function() {
        that.setState({
          successMsg: "Mensagem enviada!",
          isLoading: false,
          phone: "",
        });
        document.getElementById("simulatorContactForm").reset();
      })
      .catch(function(error) {});
  };

  render() {
    return (
      <React.Fragment>
        <div className="simulator-page" id="simulator-page">
          <h4 className="mb-0">{this.props.plan.title}</h4>
          <div className="mt-2">
            <p>
              Responda algumas perguntas rápidas e vamos indicar a opção ideal.
              Não se preocupe, os dados fornecidos não serão divulgados ou
              compartilhados.
            </p>
            <hr />
            {this.state.validationMessage && (
              <Alert variant="info">{this.state.validationMessage}</Alert>
            )}
            <Form id="simulatorForm">
              <Form.Row className="align-items-end">
                <Form.Label as={Col}>Idade Atual</Form.Label>
                <Form.Label as={Col}>Idade para Aposentadoria</Form.Label>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Control
                    type="number"
                    min="0"
                    step="1"
                    name="currentAge"
                    onChange={this.handleFields}
                    disabled={this.state.isFieldDisabled}
                  ></Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control
                    type="number"
                    min="0"
                    step="1"
                    name="retirementAge"
                    onChange={this.handleFields}
                    disabled={this.state.isFieldDisabled}
                  ></Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Sexo</Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    onChange={this.handleFields}
                    disabled={this.state.isFieldDisabled}
                  >
                    <option>Selecione</option>
                    <option value="female">Feminino</option>
                    <option value="male">Masculino</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Declaração IRRF</Form.Label>
                  <Form.Control
                    as="select"
                    name="incomeTax"
                    onChange={this.handleFields}
                    disabled={this.state.isFieldDisabled}
                  >
                    <option>Selecione</option>
                    <option value="vgbl">Simplificado</option>
                    <option value="pgbl">Completo</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <div className="text-center">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={this.handleSimulatorSubmit}
                    disabled={this.state.isFieldDisabled}
                  >
                    Simular
                  </Button>
                </div>
              </Form.Group>
            </Form>
            {this.state.showSimulatorResult && (
              <React.Fragment>
                <div className="mt-5 mb-3">
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Valor Contribuição Mensal</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          placeholder="0"
                          aria-label="Valor Contribuição Mensal"
                          value={this.state.formatedMonthlyContribution}
                          onChange={this.handleMonthlyContributionField}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>,00</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      className="rangeslider-div d-print-none"
                    >
                      <Slider
                        value={this.state.monthlyContribution}
                        orientation="horizontal"
                        onChange={(e) =>
                          this.handleMonthlyContributionField(e, "slider")
                        }
                        max={this.state.maxVolumeMonthlyContribution}
                        onChangeComplete={this.calculateMaxVolume}
                        step={1}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Renda Mensal Vitalícia</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          placeholder="0"
                          aria-label="Renda Mensal Vitalícia"
                          name="lifetimeMonthlyIncome"
                          value={this.state.formatedLifetimeMonthlyIncome}
                          // onChange={this.handleLifetimeMonthlyIncomeField}
                          readOnly
                          disabled={true}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>
                            ,
                            {this.state.lifetimeMonthlyIncomeDecimal !== 0
                              ? this.state.lifetimeMonthlyIncomeDecimal
                              : "00"}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                    {/* <Form.Group as={Col} className="rangeslider-div d-print-none">
                                 <Slider
                                    value={this.state.lifetimeMonthlyIncome}
                                    orientation="horizontal"
                                    onChange={(e) => this.handleLifetimeMonthlyIncomeField(e, "slider")}
                                    max={this.state.maxVolumeLifetimeMonthlyIncome}
                                    onChangeComplete={this.calculateMaxVolume}
                                    step={1}
                                 />
                              </Form.Group> */}
                    {/* </Form.Row>
                           <Form.Row> */}
                    <Form.Group as={Col}>
                      <Form.Label>Total Acumulado</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          placeholder="0"
                          aria-label="Total Acumulado"
                          name="cumulativeTotal"
                          value={this.state.formatedCumulativeTotal}
                          // onChange={this.handleCumulativeTotalField}
                          readOnly
                          disabled={true}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>
                            ,
                            {this.state.cumulativeTotalDecimal !== 0
                              ? this.state.cumulativeTotalDecimal
                              : "00"}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                    {/* <Form.Group as={Col} className="rangeslider-div d-print-none">
                                 <Slider
                                    value={this.state.cumulativeTotal}
                                    orientation="horizontal"
                                    onChange={(e) => this.handleCumulativeTotalField(e, "slider")}
                                    max={this.state.maxVolumeCumulativeTotal}
                                    onChangeComplete={this.calculateMaxVolume}
                                    step={1}
                                 />
                              </Form.Group> */}
                  </Form.Row>
                </div>
                <Card>
                  <Card.Header className="text-center">
                    De acordo com a simulação, o plano ideal para você é:
                  </Card.Header>
                  <Card.Body>
                    <div className="text-center">
                      <Card.Title>
                        <Badge variant="info">
                          <i className="icofont-chart-histogram"></i>
                        </Badge>{" "}
                        {this.state.incomeTax.toUpperCase()}
                      </Card.Title>
                      <Button variant="info" onClick={() => window.print()}>
                        Imprimir
                      </Button>
                      <Button variant="info" onClick={this.showContactForm}>
                        Solicitar Contato
                      </Button>
                      <Button
                        variant="info"
                        onClick={this.handleRefreshSimulatorPage}
                      >
                        Nova Simulação
                      </Button>
                      <small className="text-muted d-block mt-3">
                        Os valores apresentados aqui são de mera simulação e não
                        uma proposta formal. Entre em contato conosco para
                        maiores informações.
                      </small>
                    </div>

                    {this.state.showContactForm && (
                      <div
                        style={{ minHeight: 180, position: "relative" }}
                        className="d-print-none"
                      >
                        {this.state.isLoading ? (
                          <div className="loader"></div>
                        ) : (
                          <div className="mt-4">
                            <ValidationForm
                              id="simulatorContactForm"
                              onSubmit={(e) => {
                                e.preventDefault();
                                this.handleContactSubmit();
                              }}
                            >
                              {this.state.successMsg && (
                                <Alert variant="info">
                                  {this.state.successMsg}
                                </Alert>
                              )}
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label>Nome</Form.Label>
                                  <TextInput
                                    name="name"
                                    required
                                    errorMessage={{
                                      required: "Por favor, informe seu nome.",
                                    }}
                                    className="form-control"
                                    autoComplete="off"
                                    onChange={this.handleFields}
                                  />
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label>E-mail</Form.Label>
                                  <TextInput
                                    name="email"
                                    type="email"
                                    required
                                    errorMessage={{
                                      required:
                                        "Por favor, informe seu e-mail.",
                                      type: "Este não é um e-mail válido!",
                                    }}
                                    className="form-control"
                                    autoComplete="off"
                                    onChange={this.handleFields}
                                  />
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col}>
                                  <Form.Label>Telefone</Form.Label>
                                  <TextInput
                                    name="phone"
                                    type="text"
                                    required
                                    errorMessage={{
                                      required:
                                        "Por favor, informe seu telefone.",
                                      type: "Este não é um telefone válido!",
                                      minLength:
                                        "Necessário informar 10 caracteres, no mínimo",
                                    }}
                                    className="form-control"
                                    minLength={14}
                                    maxLength={15}
                                    autoComplete="off"
                                    onChange={(e) =>
                                      this.handleFields(e, "phone")
                                    }
                                    value={this.state.phone}
                                  />
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} className="text-center">
                                  <Button variant="primary" type="submit">
                                    Enviar
                                  </Button>
                                </Form.Group>
                              </Form.Row>
                            </ValidationForm>
                          </div>
                        )}
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
Simulator.Propstypes = {
  plan: Propstypes.array,
};
Simulator.defaultProps = {
  plan: [],
};

export default Simulator;
