import React from "react";

import { Accordion, AccordionItem } from 'react-sanfona'
import ReactHtmlParser from 'react-html-parser'

export default props => (
    <React.Fragment>
        <div className="container-faq">
            <div className="container">
                <h2 className="text-center"><b>Perguntas Frequentes</b></h2>
                <div className="row">
                    <div className="col-lg-8 col-md-10 mx-auto">
                        <Accordion className="faq-list">
                            {props.topics.map((item, index) => {
                                return (
                                    <AccordionItem title={item.title} className="faq-item" key={index}>
                                        <div className="faq-subitem">{ReactHtmlParser(item.text)}</div>
                                    </AccordionItem>
                                );
                            })}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
)